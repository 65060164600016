@import "../../styles/variables.scss";

.grid {
  display: grid;
  grid-template-columns: 1fr;

  &.gridSpaced {
    grid-column-gap: 1.5rem;
  }

  &.gridDemarcated {
    border-top: solid 1px $stony;
    border-bottom: solid 1px $stony;
    padding: 2.5rem 0;
  }

  @media (min-width: $sm-breakpoint) {

    &.gridSpaced {
      grid-column-gap: 3rem;
    }

    &.gridBalanced {
      grid-template-columns: 1fr 1fr;
    }

    &.gridWeighted {
      grid-template-columns: 3fr 2fr;
    }

    &.background {
      background-color: $light-grey;
      border-radius: 5px;
    }
  }

  @media (min-width: $md-breakpoint) {

    &.gridBalancedLarge {
      grid-template-columns: 1fr 1fr;
    }

    &.gridWeightedImage {
      grid-template-columns: 3fr 2fr;
    }

    &.background {
      background-color: $light-grey;
      border-radius: 5px;
    }
  }
}
