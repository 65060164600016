@import "../../styles/variables.scss";

.explainer {

  padding: .5rem 1rem;

  @media (min-width: $sm-breakpoint) {
    padding: .5rem 1rem 1rem 0;
  }

  h3 {
    font-size: $medium;
    color: $teal;
    margin: 0.5rem 0;
  }

  article h3 {
    text-align: left;
    margin-bottom: 0;
  }

  p {
    margin-top: 0;
  }

  ul {
    margin-top: 0;
    list-style-position: outside;
    padding-left: 20px;
    margin-bottom: 2rem;
  }

  p, li {
    max-width: 30rem;
  }

  li {
    list-style: none;
    position: relative;
    margin-bottom: 0.5rem;

    &:before {
      position: absolute;
      color: $teal;
      content: '• ';
      left: -20px;
    }
  }

  a {

    color: $plum;
    -webkit-transition: .3s;
    transition: .3s;
    text-decoration: underline;
  
    &:visited {
      color: $plum;
    }
  
    &:active,
    &:hover {
      color: $teal;
    }
  }
}