@import "../../styles/variables.scss";

.banner {
  background-color: $plum;
  padding: .5rem 1rem;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;

  @media (min-width: $sm-breakpoint) {
    padding: .5rem;
    border-top-right-radius: 5px;
    border-bottom-left-radius: 0px;
  }

  @media (min-width: $md-breakpoint) {
    padding: 1rem;
  }

  h2 {
    font-size: 2.618rem;
    line-height: 1.36;
    font-weight: 300;
    margin-bottom: 0;
    @media (min-width: $sm-breakpoint) {
      font-size: 40px;
    }
    @media (min-width: $md-breakpoint) {
      font-size: $large;
    }
  }

  h2, p {
    color: $off-white;
    @media (min-width: $sm-breakpoint) {
      padding: .5rem 1rem;
    }
  }

  p {
    line-height: 1.5;
    font-size: 1.5rem;
    font-weight: 300;

    @media (min-width: $sm-breakpoint) {
      font-size: 22px;
    }

    @media (min-width: $md-breakpoint) {
      font-size: 24px;
    }
  }
}