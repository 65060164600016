@import "../../styles/variables.scss";

.vectorImage {
  svg {
    display: block;
    margin: 0 auto;
    height: 214px;
    width: 219px;

    g {
      path:first-of-type {
        fill: $light-grey;
      }
    }

    @media (min-width: $sm-breakpoint) {
      height: 137px;
      width: 140px;
      g {
        path:first-of-type {
          fill: white;
        }
      }
    }
  }
}