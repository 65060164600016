@import "../../styles/variables.scss";

.panel {
  background-color: $pale-blue;
  border-top: 0.5rem solid $teal;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  text-align: center;
  padding: 2rem 2rem 3rem;

  svg {
    margin: 1rem auto 1.5rem;
    position: relative;
    display: block;
  }

  a {
    color: $plum;
    transition: .3s;
    text-decoration: underline;

    &:hover {
      color: $teal;
    }
  }
}
