@import "../../styles/variables.scss";

.contactItem {

  margin-bottom: 2rem;

  h3 {
    color: $teal;
    font-size: $medium;
    line-height: 1.5;
    margin: 1rem 0;
    @media (min-width: $sm-breakpoint) {
      margin: 0.5rem 0;
    }
  }

  p {
    margin: 1rem 0;
    max-width: 30em;
    overflow-wrap: break-word;
  }

  svg {
    display: inline-block;
  }
}