@import "../../styles/variables.scss";

.header {
  width: 100%;
  position: relative;

  h1 {
    display:  block;
    overflow: hidden;
    text-indent: 100%;
    white-space: nowrap;
    font-size: 2px;
  }
}