@import "../../styles/variables.scss";

.info {

  margin-bottom: 2rem;
  overflow-wrap: break-word;

  &:nth-of-type(2) {
    margin-bottom: 1.5rem;
  }

  h3 {
    color: $teal;
    font-size: $medium;
    margin: 1rem 0;
  }

  p {
    margin: 1rem 0;
    max-width: 30em;
  }
}