@import "../../styles/variables.scss";

.cta {
  text-align: center;
  padding: 2.5rem 0 .5rem;
  max-width: 80vw;
  width: 100%;
  margin: 0 auto;
  overflow-wrap: break-word;

  @media (min-width: $sm-breakpoint) {
    width: 20vw;
    margin: 0 auto;
    padding: 4.5rem 0;
  }


  .ctaButton,
  .ctaButtonDisabled {
    padding: 15px;
    transition: .2s;
    width: 100%;
    border: none;
    border-radius: 1.5rem;
    color: $light-grey;
    margin-top: 2rem;
  }

  .ctaButton {
    background-color: $plum;
    font-size: 22px;

    &:focus, 
    &:hover {
      background-color: $teal;
    }

    &:focus {
      border: solid 2px $orange;
    }
  }

  .ctaButton {
    cursor: pointer;
  }

  .ctaButtonDisabled {
    background-color: $dark-grey;
    font-size: 22px;
    color: $light-grey;
  }

}

.ctaOffline {
  background-color: $pale-plum;
  border-left: 5px solid $plum;
  color: $stormy;
  margin-bottom: 1rem;
  padding: .5rem 1rem;
  @media (min-width: $sm-breakpoint) {
    width: 30rem;
  }
}

.option {
  margin-top: 1rem;
  p {
    font-size: 12px;
  }
}
