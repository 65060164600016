@import "../../styles/variables.scss";

.contact {

  border-bottom: solid 1px $less-dark-grey;
  padding-bottom: 24px;
  
  a {
    font-size: $regular-px;
    font-size: $regular;
  }
    
  @media (min-width: $sm-breakpoint) {

    border-left: solid 1px $teal;
    border-bottom: none;
    padding: 0 1.5rem;
    margin: 3.5rem 0;
  }

  svg {
    position: relative;
    top: 2px;
    margin-right: 10px;
  }
}