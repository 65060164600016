@import "../../styles/variables.scss";

@mixin rowMargin() {
  margin: 0 auto;
  padding: 0.5rem 4vw;
  max-width: 92vw;
  @media (min-width: $md-breakpoint) {
    max-width: 72vw;
  }
}

.row {
  @include rowMargin()
}

.rowGrey {

  background-color: $off-white;
  border-top: 1px solid $stony;
  border-bottom: 1px solid $stony;

  div {
    text-align: center;
    @include rowMargin();
  }
}

.rowWhite {

  background-color: $white;
  margin-top: 1rem;
  margin-bottom: 1rem;


  div {
    text-align: center;
    @include rowMargin();

    p {
      color: $teal;
    }
  }
}