//
// colour variables
//

$plum: #b6006c; // key colour
$mulled: #640140; // darker shade
$chocolate: #301021; // darkest shade

// complementary colours
$teal: #2D7d9E;
$green: #00A944;
$dark-green: #007E33;
$orange: #F7901D;
$blue: #71CEE8;
$pale-blue: #E3F5FC;
$pale-plum: #F7E5F0;

// greys
$light-grey: #F0F4F5;
$stormy: #414042;
$rhino: #58585B;
$stony: #e6e7e8;
$less-dark-grey: #979797;
$dark-grey: #757575;

$white: #FFFFFF;
$off-white: #F7F7F7;

//
// font variables
//

$small-px: 15px;
$regular-px: 18px;
$medium-px: 28px;
$large-px: 50px;

$small: .618rem;
$regular: 1rem; //md
$medium: 1.618rem; //lg
$large: 2.618rem; //xl

//
// breakpoints
//

$sm-breakpoint: 50em;
$sm2-breakpoint: 64em;
$md-breakpoint: 90em;
