@import "../../styles/variables.scss";

.infoLink {

  overflow-wrap: break-word;
  word-break: break-all;
  
  a {
    margin-right: 5px;

    color: $plum;
    -webkit-transition: .3s;
    transition: .3s;
    text-decoration: underline;
  
    &:visited {
      color: $plum;
    }
  
    &:active,
    &:hover {
      color: $teal;
    }
  }

  span {
    display: inline-block;
    width: 8px;
    height: 8px;
    border-top: 2px solid $plum;
    border-right: 2px solid $plum;
    transform: rotate(45deg);
  }

  &:active, 
  &:hover {
    color: $teal;

    a {
      margin-right: .5rem;
    }

    span {
      color: $teal;
      border-top-color: $teal;
      border-right-color: $teal;
    }
  }
}